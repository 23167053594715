import React, { useEffect, useState } from "react";
import Head from "./head";
import Footer from "./footer";
import Cours from "./coursel";
import "./bo.css"
import Coursel from "./coursel";
import { NavLink } from "react-router-dom";

import photo1 from "../media/téléchargement.png";
import photo2 from "../media/Baudouin.jpg";
import photo3 from "../media/cummins.png";
import photo4 from "../media/yuchai.png";
import photo5 from "../media/perkins.png";
import photo6 from "../media/p2.jpg";

import { PiTreeStructure } from "react-icons/pi";
import { IoSettingsOutline } from "react-icons/io5";
import { PiHandshakeLight } from "react-icons/pi";
import { FaPeopleGroup } from "react-icons/fa6";
import { BiSolidMedal } from "react-icons/bi";
import axios from "axios";



function Home() {

  const [contenu, setcontenu]=useState("")
  const [data,setdata] = useState("")

  const obtain =async()=>{
    axios.get("https://api.sanypower.net/groupe/aleatoire/")
  .then((response) => setdata(response.data))
  .catch((error)=> console.log(error))

  }
 console.log(data)
  useEffect(() =>{
    obtain();
  },[])

  useEffect(() =>{
    if(data){
      setcontenu( 
        <div className="classe"> 

        <div className="classe_s">

          <div className="ca"> <Cours  data ={data.slice(10,15)}/> </div>
          <div className="c"> <p> Vous recherchez une source d'énergie fiable et puissante pour vos besoins personnels ou professionnels ? Nos groupes électrogènes sont la solution idéale pour garantir une alimentation électrique continue et stable, peu importe les conditions.  </p> </div>

        </div>
        
        <div className="classe_s">

          <div className="c"> <p>Ne laissez pas la panne de courant vous ralentir. Optez pour la tranquillité d'esprit avec nos groupes électrogènes !

Contactez-nous maintenant  ou envoyez-nous un email pour plus d'informations ! </p> </div>
          <div className="ca" > <Cours  data ={data.slice(6,10)}/> </div>

        
        </div>
            
            
            
            </div>
    
    
    )
    }
  },[data])

  
  
  
 
  

  
   
    return (
        <div className="Home">

          <Head />
          <div className="bo">
          <div className="na"> <h2> NOS ARTICLES EN ALEATOIRE </h2> <hr/></div>
            
            {contenu}
            <div className="na"> <h2> NOTRE CATALOGUE </h2> <hr/></div>
            <div className="classe1"> 
            
            <p></p> 
              

              <div className="p1">



                <div className="cat">
                  <a href="/liste/1/WEICHAI">
                      <img src={photo1}/>
                      <p>WEICHAI</p>

                  </a>
                  
                </div>
                
                <div className="cat">
                 
                  <a href="/liste/2/BAUDOIN" >
                      <img src={photo2}/>
                      <p>BAUDOIN</p>

                  </a>
                 
                </div>
                <div className="cat">
                  <a href="/liste/3/CUMMINS">
                      <img src={photo3}/>
                      <p>CUMMINS</p>

                  </a>
                  
                </div>
                <div className="cat">
                  <a href="/liste/4/YUCHAI">
                      <img src={photo4}/>
                      <p>YUCHAI</p>

                  </a>
                  
                </div>
                <div className="cat">
                  <a href="/liste/5/PERKINS">
                      <img src={photo5}/>
                      <p>PERKINS</p>

                  </a>
                  
                </div>

                {/* <div className="cat">
                  <a href="/liste/6/divers">
                      <img src={photo1}/>
                      <p>DIVERS....</p>

                  </a>
                  
                </div> */}
                
                 

            
            </div>

            
            </div>
            <div className="part2">
             
             <h2 >CAS DE SUCCÉS</h2><hr/>

             <div className="cas">
                    {/* <div className="cas1" >
                        <img src="https://images.pexels.com/photos/955394/pexels-photo-955394.jpeg"/>
                        <p>Licence pour faire des affaires en Ouzbékistan</p>
                    </div>
                    <div  className="cas1" >
                        <img src="https://images.pexels.com/photos/5256816/pexels-photo-5256816.jpeg?auto=compress&cs=tinysrgb&w=1200"/>
                        <p>Permis pour les services d'agence commerciale SUPERPOWER</p>
                    </div>
                    <div  className="cas1" ><img src="https://images.pexels.com/photos/7735630/pexels-photo-7735630.jpeg?auto=compress&cs=tinysrgb&w=1200"/><p>Permis de service de l'agence commerciale Weichai</p></div>
                    <div  className="cas1" ><img src="https://images.pexels.com/photos/8428080/pexels-photo-8428080.jpeg?auto=compress&cs=tinysrgb&w=1200"/><p>Permis de service d'agence commerciale Yu Chai</p></div> */}


  <input type="radio" name="position"  />
  
  <input type="radio" name="position"  />
  <input type="radio" name="position" />
  <input type="radio" name="position" />
  
  
  <div className="carol">

    <div className="item"> 
    <img src="https://images.pexels.com/photos/955394/pexels-photo-955394.jpeg"/>
    <p>Licence pour faire des affaires en Ouzbékistan</p>
    </div>
   
    <div className="item">
    <img src="https://images.pexels.com/photos/5256816/pexels-photo-5256816.jpeg?auto=compress&cs=tinysrgb&w=1200"/>
    <p>Permis pour les services d'agence commerciale SUPERPOWER</p>
    </div>
    <div className="item">
    <img src="https://images.pexels.com/photos/7735630/pexels-photo-7735630.jpeg?auto=compress&cs=tinysrgb&w=1200"/>
    <p>Permis de service de l'agence commerciale Weichai</p>
    </div>
    <div className="item">
    <img src="https://images.pexels.com/photos/8428080/pexels-photo-8428080.jpeg?auto=compress&cs=tinysrgb&w=1200"/>
    <p>Permis de service d'agence commerciale Yu Chai</p>
    </div>
    
    </div>

            </div>

            </div>

            <div className="part3">
            <img src="https://omo-oss-image.thefastimg.com/portal-saas/pg2024042214040033899/cms/image/1a55c83d-349e-4852-91cd-a70d20a84dcd.jpg_1180xaf.jpg"/>
            <div className="p3">
              <h3>SEER POWER New Energie co.,LTD</h3>
              <p>Chez Sanypowergroup, nous sommes passionnés par le pouvoir. Depuis notre création, nous nous engageons à fournir à nos clients des générateurs de la plus haute qualité à des prix abordables. Notre mission est de garantir que vos opérations ne s'arrêtent jamais en raison de coupures d'électricité....</p>
              <a href="/presentation/">voir plus</a>
            </div>

            </div>
           
            <div className="part4">
             
             <h2 >POURQUOI NOUS CHOISIR ?</h2><hr/>

             <div className="p4">
             
                <div className="gp1">
                 
                <FaPeopleGroup />

                    
                  
                <div className="g2">
                  <h3>Capacité techniques</h3>
                  <p>L'entreprise dispose d'une structure de talents raisonnable, avec plus de 50 % de ses employés ayant un niveau universitaire ou supérieur. La plupart des chefs d'équipe de projet et...</p>
                </div>

                </div>
                <div className="gp1">
                 
                <IoSettingsOutline />
                    
                  
                <div className="g2">
                  <h3>Capacité de production</h3>
                  <p>Le centre de R&D de l'entreprise au Hunan couvre une superficie de plus de 4 200 mètres carrés et la base de production du Fujian couvre une superficie de plus de 10 000 mètres carrés. Il existe des ateliers de traitement, des centres de tests et des ateliers de maintenance à grande échelle...</p>
                </div>

                </div>
             
             </div>
             <div className="p4">
             
                <div className="gp1">
                 
                <BiSolidMedal />
                    
                  
                <div className="g2">
                  <h3>Niveau de gestion</h3>
                  <p>Le système de gestion de la qualité de l'entreprise a passé la certification internationale ISO9001. Avant la mise en œuvre du projet, l'équipe du projet élaborera un...</p>
                </div>

                </div>
                <div className="gp1">
                 
                <PiHandshakeLight />
                    
                  
                <div className="g2">
                  <h3>Réputation de qualité</h3>
                  <p>Notation de crédit bancaire d’ouverture : niveau AAA. Le flux de capitaux entre l’entreprise et la banque d’ouverture est normal et la solvabilité est bonne. Taux de satisfaction client</p>
                </div>

                </div>
             
             </div>
            
             </div>
          </div>
          <Footer></Footer>
          
        </div>
      );
}
export default Home;